import React from 'react';
import { Avatar, Typography, Grid, Paper } from '@mui/material';
import { MailOutline, LocationOn, Phone } from '@mui/icons-material';
import { MainHeader } from '../header/MainHeader';

const UserProfile = ({ user }) => {
  return (
    <div>
      <MainHeader/>
      <div>ankit</div>
    </div>

  );
};

export default UserProfile;
